<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Chính sách bảo mật</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/vajefinance/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Chúng tôi tôn trọng quyền riêng tư của mọi người truy cập trang web này và cam kết bảo vệ thông tin cá nhân của họ. Chính sách bảo mật này áp dụng cho tất cả khách truy cập vào trang web của chúng tôi và cho tất cả các công ty con của chúng tôi.

Vì mục đích của Chính sách quyền riêng tư này, các thuật ngữ “bạn” hoặc “người dùng” đề cập đến bất kỳ thể nhân hoặc pháp nhân nào truy cập trang web này, bao gồm cả khách hàng trực tuyến và ngoại tuyến
Bằng cách sử dụng trang web này, bạn thừa nhận và đồng ý với Chính sách quyền riêng tư này. Tuy nhiên, nếu bạn phản đối việc chúng tôi sử dụng, xử lý và chuyển thông tin của bạn theo bất kỳ cách nào, vui lòng không chia sẻ thông tin của bạn trên Trang web.

Các loại thông tin cá nhân được thu thập
Chúng tôi có thể thu thập thông tin cá nhân như được mô tả bên dưới nhằm mục đích cung cấp dịch vụ:

Thông tin bạn có thể cung cấp trực tiếp, chẳng hạn như:
Thông tin nhận dạng: tên, giới tính, địa chỉ cư trú/thư tín, số điện thoại, ngày sinh, tình trạng hôn nhân, địa chỉ email hoặc thông tin liên hệ khác.
Trạng thái PAN, KYC, chữ ký và ảnh.
Tài khoản ngân hàng hoặc chi tiết công cụ thanh toán khác.
Bất kỳ chi tiết nào khác mà chúng tôi có thể cần để cung cấp dịch vụ
Thông tin chúng tôi có thể thu thập khi bạn sử dụng dịch vụ của chúng tôi, chẳng hạn như:
Thông tin giao dịch: Chúng tôi chỉ đọc, thu thập và giám sát tin nhắn giao dịch tài chính để mô tả các giao dịch và số tiền tương ứng nhằm đánh giá rủi ro tín dụng. Không có dữ liệu SMS nào khác được truy cập.
Thông tin lưu trữ: Chúng tôi có thể tạo điều kiện cho người dùng tải xuống và hiển thị thông tin như chi tiết ủy thác kế hoạch để người dùng tham khảo hoặc tải lên các tài liệu liên quan trong các quy trình khác nhau như quản lý tài khoản người dùng hoặc đặt lệnh giao dịch.
Thông tin phương tiện: Chúng tôi tạo điều kiện cho người dùng thu thập/tải lên các tài liệu liên quan có thể cần tải lên trong quá trình quản lý tài khoản người dùng hoặc đặt lệnh giao dịch.
Thông tin thiết bị: Khi bạn truy cập Dịch vụ của chúng tôi, chúng tôi sẽ thu thập một số thông tin nhất định về thiết bị của bạn, bao gồm dung lượng lưu trữ, kiểu phần cứng, hệ điều hành và phiên bản, số nhận dạng thiết bị duy nhất, thông tin mạng di động và thông tin về thiết bị của bạn và thông tin tương tác Dịch vụ của chúng tôi.

Khi bạn chọn một liên hệ làm người tham khảo trong quá trình cho vay, chúng tôi sẽ đọc thông tin tên và số điện thoại. Chúng tôi không tải danh sách liên hệ của bạn lên máy chủ của chúng tôi.
Thông tin tệp nhật ký được lưu trữ tự động:
Nếu bạn truy cập/đăng nhập vào trang web của chúng tôi chỉ để duyệt, đọc trang hoặc tải xuống thông tin, một số thông tin nhất định về lượt truy cập của bạn sẽ tự động được lưu trữ trong hệ thống của chúng tôi. Thông tin này không thể và sẽ không nhận dạng cá nhân bạn.

Các loại thông tin được thu thập tự động bao gồm nhưng không giới hạn ở:

Loại trình duyệt bạn sử dụng (ví dụ: Internet Explorer, Firefox, v.v.),
Loại hệ điều hành bạn đang sử dụng (chẳng hạn như Windows hoặc Mac OS) và
Tên miền của nhà cung cấp dịch vụ internet của bạn, ngày giờ bạn truy cập và các trang trên trang web của chúng tôi.
Đôi khi chúng tôi sử dụng thông tin này để cải thiện thiết kế và nội dung trang web của mình, chủ yếu là để cung cấp cho bạn trải nghiệm duyệt web tốt hơn.

Mục đích thu thập và sử dụng thông tin
Trên trang web của chúng tôi, chúng tôi chỉ thu thập, lưu giữ và sử dụng thông tin của bạn khi chúng tôi có lý do để tin rằng thông tin của bạn sẽ giúp quản lý hoạt động kinh doanh của chúng tôi hoặc cung cấp sản phẩm, dịch vụ và các cơ hội khác cho bạn. Thông tin này được thu thập cho các mục đích kinh doanh cụ thể, chẳng hạn như:

Để cung cấp cho bạn các dịch vụ bạn có thể cần,
Xử lý các yêu cầu giao dịch tài chính và phi tài chính của bạn,
tiến hành nghiên cứu và phân tích để cung cấp hoặc cải thiện dịch vụ của chúng tôi,
để xem xét và xử lý các đơn đăng ký bạn có thể gửi để sử dụng bất kỳ dịch vụ tài chính nào,
chia sẻ với bạn mọi cập nhật/thay đổi đối với Dịch vụ cũng như các điều khoản và điều kiện của nó,
chấp nhận và điều tra mọi khiếu nại/khiếu nại/tranh chấp,
Trả lời các câu hỏi và phản hồi đã gửi của bạn,
để xác minh danh tính của bạn và các thông số khác,
Để tuân thủ các yêu cầu của luật/quy định hiện hành và/hoặc lệnh của tòa án/chỉ thị quy định mà chúng tôi nhận được.
Công bố thông tin
Thông tin bạn cung cấp có thể được tiết lộ cho:
bất kỳ hoạt động kinh doanh hoặc tổ chức lại, sáp nhập, tổ chức lại doanh nghiệp hoặc vì bất kỳ lý do nào khác của một thực thể kinh doanh khác,
bất kỳ cơ quan tư pháp hoặc quản lý nào,
kiểm toán viên,
Các nhà cung cấp dịch vụ bên thứ ba khác.
Chúng tôi sẽ không tiết lộ hoặc tiết lộ thêm dữ liệu hoặc thông tin cá nhân nhạy cảm cho bất kỳ mục đích nào khác ngoài mục đích nêu trên mà không có sự đồng ý của bạn.

Lưu giữ thông tin
Chúng tôi sẽ không lưu giữ hoặc lưu trữ thông tin đó lâu hơn mức cần thiết cho mục đích đó, trừ khi thông tin đó có thể được sử dụng hợp pháp hoặc được yêu cầu theo bất kỳ luật nào khác tại thời điểm hiện hành.

Bằng việc đồng ý sử dụng các dịch vụ chúng tôi cung cấp, bạn cũng đồng ý với việc chúng tôi thu thập và sử dụng dữ liệu hoặc thông tin cá nhân nhạy cảm của bạn. Bạn luôn có quyền từ chối hoặc rút lại sự đồng ý của mình đối với việc chia sẻ/phổ biến dữ liệu hoặc thông tin cá nhân nhạy cảm của bạn bằng cách liên hệ với bộ phận dịch vụ khách hàng. Tuy nhiên, nếu bạn từ chối hoặc rút dữ liệu cá nhân của mình, bạn sẽ không thể tận dụng tối đa bất kỳ dịch vụ nào của chúng tôi.

Truyền thông và thông báo
Khi bạn sử dụng Trang web hoặc gửi email hoặc dữ liệu, thông tin hoặc thông tin liên lạc khác cho chúng tôi, bạn đồng ý và hiểu rằng bạn đang liên lạc với chúng tôi bằng điện tử và bạn đồng ý nhận thông tin liên lạc định kỳ từ chúng tôi. Chúng tôi có thể cung cấp thông báo cho bạn qua email hoặc thông báo bằng văn bản hoặc bằng cách đăng thông báo đó một cách nổi bật trên các trang web của chúng tôi. Bạn có thể chọn không tham gia một số phương thức thông báo nhất định nếu bạn thấy phù hợp.

Cập nhật hoặc xem lại thông tin của bạn
Bạn có thể xem lại dữ liệu cá nhân hoặc thông tin bạn đã cung cấp theo yêu cầu bằng văn bản cho chúng tôi. Chúng tôi sẽ đảm bảo rằng mọi thông tin cá nhân hoặc dữ liệu cá nhân nhạy cảm hoặc thông tin được phát hiện là không chính xác hoặc bị lỗi sẽ được sửa chữa hoặc sửa đổi nếu có thể.

Thực hành bảo mật hợp lý để bảo vệ thông tin của bạn
Chúng tôi sử dụng các biện pháp bảo vệ vật lý, hành chính và kỹ thuật hợp lý về mặt thương mại để bảo vệ tính toàn vẹn và bảo mật thông tin cá nhân của bạn. Điều này bao gồm đánh giá nội bộ về các hoạt động thu thập, lưu trữ và xử lý dữ liệu cũng như các biện pháp bảo mật của chúng tôi, chẳng hạn như các biện pháp bảo mật vật lý và mã hóa thích hợp, để ngăn chặn truy cập trái phép vào hệ thống của chúng tôi nơi lưu trữ dữ liệu cá nhân.

Tất cả thông tin được thu thập trên các trang web nêu trên được lưu trữ an toàn trong cơ sở dữ liệu do chúng tôi kiểm soát. Cơ sở dữ liệu được lưu trữ trên một máy chủ an toàn; quyền truy cập vào nó được bảo vệ bằng mật khẩu và bị hạn chế nghiêm ngặt.

Để bảo vệ quyền riêng tư và bảo mật của bạn, chúng tôi thực hiện các bước hợp lý (chẳng hạn như yêu cầu mật khẩu duy nhất) để xác minh danh tính của bạn trước khi cấp quyền truy cập vào tài khoản của bạn. Bạn có trách nhiệm duy trì tính bảo mật của thông tin tài khoản và mật khẩu duy nhất của mình cũng như kiểm soát quyền truy cập của chúng tôi vào thông tin liên lạc qua email của bạn mọi lúc

Mặc dù chúng tôi sử dụng các biện pháp bảo mật để giúp bảo vệ thông tin cá nhân của bạn khỏi bị tiết lộ, lạm dụng hoặc thay đổi trái phép (như tất cả các mạng máy tính được kết nối với Internet), chúng tôi không thể đảm bảo hoặc đảm bảo tính bảo mật của bất kỳ thông tin nào bạn truyền cho chúng tôi. rủi ro. Khi chúng tôi nhận được thông tin bạn truyền tải, chúng tôi sẽ sử dụng các nỗ lực hợp lý về mặt thương mại để đảm bảo tính bảo mật của thông tin đó.

Liên kết đến các trang web khác
Xin lưu ý rằng Chính sách quyền riêng tư này không áp dụng cho các trang web của bên thứ ba được liên kết với trang web này. Chúng tôi không chịu trách nhiệm về nội dung và các biện pháp bảo mật của các trang liên kết đó. Bạn nên đọc tuyên bố về quyền riêng tư của từng trang web được liên kết như vậy trước khi chia sẻ bất kỳ thông tin nào.

Thay đổi chính sách bảo mật của chúng tôi
Xin lưu ý rằng chính sách này có thể thay đổi theo thời gian. Nếu chúng tôi thay đổi chính sách và quy trình bảo mật, chúng tôi sẽ đăng những thay đổi đó trên trang web của mình để thông báo cho bạn. Những thay đổi đối với chính sách này có hiệu lực kể từ ngày chúng được đăng trên trang này. Vui lòng truy cập trang web của chúng tôi để biết bất kỳ thay đổi nào đối với chính sách này.

Khiếu nại:
Bất kỳ sự khác biệt và khiếu nại nào liên quan đến việc xử lý và sử dụng thông tin của bạn đều có thể được nêu ra với nhân viên giải quyết khiếu nại được chỉ định của chúng tôi. Để biết thêm chi tiết, vui lòng truy cập Thủ tục giải quyết khiếu nại.`,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3400px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: auto;
  margin-top: -190px;
  transform: scale(87%,95%);
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
border-bottom: 7px solid #21AF67;
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>